import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import PageHero from '../components/PageHero';
import HeroFeatureSection from '../components/HeroFeatureSection';

const IndexPage = ({
  data: {
    homepage
  },
}) => {
  return(
  <Layout title={homepage.edges[0].node.data.meta_title} desc={homepage.edges[0].node.data.meta_description} img={homepage.edges[0].node.data.image} social={homepage.edges[0].node.data.social_body}>
    {homepage.edges[0].node.data.body.map((slice, idx) => {
      switch (slice.__typename) {
        case 'PrismicHomepageBodyHero':
          return <PageHero key={slice.id} data={slice.primary} />;
        case 'PrismicHomepageBodyHeroFeatureSection':
          return <HeroFeatureSection key={idx} data={slice.primary} />
        default:
          return <div key={idx}>unknown slice {slice.__typename}</div>
      }
    })}
  </Layout>
);
}
export default IndexPage;

IndexPage.propTypes = {
  data: PropTypes.shape({
    homepage: PropTypes.shape({
      edges: PropTypes.array.isRequired,
    })
  }).isRequired,
};

export const pageQuery = graphql`
  query IndexQuery {
    homepage: allPrismicHomepage {
      edges {
        node {
          id
          data {
            page_title {
              html
            }
            page_description {
              html
            }
            meta_title
            meta_description
            image {
              localFile {
                childImageSharp {
                  fixed {
                    src
                  }
                }
              }
              alt
              url
            }
            social_body {
              __typename
              ... on PrismicHomepageSocialBodyTwitterCard {
                id
                primary {
                  card_type
                  title {
                    html
                    text
                  }
                  twitter_handle
                  image {
                    localFile {
                      childImageSharp {
                        fixed {
                          ...GatsbyImageSharpFixed
                        }
                      }
                    }
                    alt
                  }
                }
              }
              ... on PrismicHomepageSocialBodyGeneralCard {
                id
                primary {
                  title {
                    html
                    text
                  }
                  description {
                    html
                    text
                  }
                  image {

                    alt
                    url
                  }
                }
              }
            }
            body {
              __typename
              ... on PrismicHomepageBodyHeroFeatureSection{
                primary{
                  feature_subtitle {
                    html
                    text
                  }
                  client_title {
                    html
                    text
                  }
                  project_title {
                    html
                    text
                  }
                  project_link_url
                  button_colour
                  button_link_label
                  button_link
                  feature_image{
                    localFile {
                      childImageSharp {
                        fluid (maxWidth:1920) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                    alt
                    url
                  }
                }
              }
              ... on PrismicHomepageBodyHero {
                id
                primary {
                  hero_title {
                    html
                    text
                  }
                  hero_body {
                    html
                  }
                  background_image {
                    localFile {
                      childImageSharp {
                        fluid (maxWidth: 1920) {
                          ...GatsbyImageSharpFluid_noBase64
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
